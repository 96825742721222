<template>
  <div class="flex flex-col">
    <label
      v-if="label"
      :class="`${labelClass} ${
        errors[name] ? 'text-red-500' : ''
      } mb-3 block font-medium`"
    >
      {{ label }} <span v-if="required" class="required">*</span>
      <slot name="after-label"></slot>
    </label>

    <slot></slot>

    <small class="text-red-500" v-if="errors[name]">{{
      errors[name][0]
    }}</small>
  </div>
</template>
<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: "text-primary",
    },
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.required {
  color: red;
}
</style>
```
